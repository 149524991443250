import React, {useEffect, useMemo, useState} from 'react';
import {useSelector} from "react-redux";
import {formCheckErrors, formGenerateErrorsObject} from "../../../../core/form";
import Loading from "../../../../components/ui/Loading";
import Field from "../../../../components/ui/Field";
import moment from "moment";
import Button from "../../../../components/ui/Button";
import {NavLink, useNavigate, useParams} from "react-router-dom";
import VideoFiles from "../../../../components/ui/VideoFiles";
import {
  apiVideoAccept, apiVideoChangeStatus, apiVideoCopy,
  apiVideoDecline,
  apiVideoGet,
  apiVideoTagsSearch,
  apiVideoUpdate
} from "../../../../actions/videoActions";
import VideoPlayer from "../../../../components/ui/VideoPlayer";
import {apiCategoriesGet} from "../../../../actions/categoriesActions";
import Partners from "./components/Partners";
import {getDateFromString, isEmpty} from "../../../../core/helpers";
import Sellings from "./components/Sellings";
import useModal from "../../../../hooks/useModal";
import ChangeAuthorModal from "../../../../components/modals/ChangeAuthorModal";
import MoveVideoModal from "../../../../components/modals/MoveVideoModal";

const errorsList = {}

const initialValues = {
  id: null,
  title: '',
  title_partners: '',
  description: '',
  description_partners: '',
  location : null,
  filmingDate: moment().toDate(),
  categories: [],
  tags: [],
  authorEmail: '',
  inGallery: 'Yes',
  status: 1,
  foreignId: null
}

function VideoPage() {

  const token = useSelector(state => state.user.token)
  const { id } = useParams();
  const navigate = useNavigate();

  const [values, setValues] = useState<any>(initialValues)
  const [errors, setErrors] = useState<any>(formGenerateErrorsObject(errorsList))
  const [loading, setLoading] = useState(true)
  const [files, setFiles] = useState<any>([])
  const [user, setUser] = useState<any>(null)
  const [activeFileId, setActiveFileId] = useState<any>(null)
  const [video, setVideo] = useState<any>(null)


  const [saveLoading, setSaveLoading] = useState(false)
  const [acceptLoading, setAcceptLoading] = useState(false)
  const [holdLoading, setHoldLoading] = useState(false)
  const [declineLoading, setDeclineLoading] = useState(false)
  const [copyLoading, setCopyLoading] = useState(false)

  const [changeAuthorModalOpen, openChangeAuthorModal, closeChangeAuthorModal] = useModal()
  const [moveFileModalOpen, openMoveFileModal, closeMoveFileModal, moveFileId] = useModal()

  useEffect(() => {

    loadData();

  }, [])

  const loadData = async () => {

    if (id !== undefined) {

      const response : any = await apiVideoGet(token, id.toString());
      if (response.status) {
        const video = response.video;
        const files = response.files.map((file: any) => {
          return {
            percent: 100,
            completed: true,
            downloadLink: file.originalPath,
            thumbLink: file.thumbPath,
            id: file.id,
            size: file.size,
            filename: file.filename,
            num: file.num,
            deleted: file.deleted,
            status: file.status
          }
        })
        setValues({
          ...values,
          id: video.id,
          title: video.title,
          title_partners: video.title_partners,
          description: video.description,
          description_partners: video.description_partners,
          location: video.location_place_id !== null ? {
            location_text: video.location_text,
            location_place_id: video.location_place_id,
            location_lat: video.location_lat,
            location_lng: video.location_lng,
            location_country: video.location_country,
            location_city: video.location_city,
          } : null,
          filmingDate: getDateFromString(video.filming_date),
          categories: response.categories,
          tags: response.tags,
          authorEmail: video.author_email,
          inGallery: video.public === 1 ? 'Yes' : 'No',
          status: video.status,
          foreignId: video.foreign_id
        })
        setFiles([
          ...files
        ])
        setVideo(video)
        setUser(response.user);
        if (files.length > 0) {
          setActiveFileId(files[0].id)
        }
        setLoading(false)
      }

    }

  }

  const changeField = (field: string, value: any) => {
    setValues({...values, [field]: value})
    setErrors({...errors, [field]: ""})
  }

  const save = async () => {

    const errorResponse = formCheckErrors(errorsList, errors, values);

    if (errorResponse.hasError) {
      setErrors({...errorResponse.errors});
      return;
    }

    setSaveLoading(true)

    if (id) {
      await apiVideoUpdate(
        token,
        id.toString(),
        values.title,
        values.title_partners,
        values.description,
        values.description_partners,
        values.location,
        values.filmingDate,
        files,
        values.categories.join(','),
        values.tags.map((tag: any) => tag.toLowerCase()).join(','),
        values.authorEmail,
        values.inGallery === 'Yes' ? '1' : '0'
      )
    }

    setSaveLoading(false)

  }

  const copy = async () => {

    setCopyLoading(true)
    const response = await apiVideoCopy(token, id);
    if (response.status) {
      window.open(`https://stringershub.com/panel/videos/${response.video_id}`, '_blank');
      setCopyLoading(false)
    }

  }

  const hold = async () => {

    setHoldLoading(true)
    await apiVideoChangeStatus(token, id, values.status === 3 ? 1 : 3)
    changeField('status', values.status === 3 ? 1 : 3)
    setHoldLoading(false)

  }

  const accept = async () => {

    setAcceptLoading(true)
    await apiVideoAccept(token, id);
    changeField('status', 2)
    setAcceptLoading(false)

  }

  const decline = async () => {

    setDeclineLoading(true)
    await apiVideoDecline(token, id)
    changeField('status', 0)
    setDeclineLoading(false)

  }

  const getPlayer = () => {
    const file = files.find((file: any) => file.id === activeFileId)
    if (file !== undefined) {

      return (
        <div className="video-page-player">
          <VideoPlayer src={file.downloadLink}/>
        </div>
      )

    }
  }

  const player = useMemo(() => getPlayer(), [activeFileId]);

  const isAcceptDisabled= () => {

    if (
      isEmpty(values.title)
      || isEmpty(values.description)
      || values.filming_date === null
      || values.location === null
      || values.categories.length === 0
      || files.filter((file: any) => (parseInt(file.status) === 2 || parseInt(file.status) === 3) && parseInt(file.deleted) === 0) === 0
    ) {
      return true
    }

    return false

  }

  const onMovePress = (fileId: string) => {
    openMoveFileModal(fileId)
  }

  const copyDescription = () => {
    changeField('description_partners', values.description)
  }

  if (loading) {
    return <Loading/>
  }

  return (
    <>
      <ChangeAuthorModal
        open={changeAuthorModalOpen}
        onClose={closeChangeAuthorModal}
        videoId={values.id}
        onUpdate={() => {
          closeChangeAuthorModal()
          window.location.reload()
        }}
      />
      <MoveVideoModal
        open={moveFileModalOpen}
        onClose={closeMoveFileModal}
        fileId={moveFileId}
        onUpdate={() => {
          closeMoveFileModal()
          window.location.reload()
        }}
      />
      <div className="video-page-top">
        <div className="video-page-top-back-icon" onClick={() => {
          navigate(-1);
        }}>
          <i className="fa fa-angle-left"/>
        </div>
        <span className="video-page-top-title">Video</span>
        <div className="video-page-top-right">
          {values.status === 3 && !acceptLoading && !declineLoading && (
            <Button
              className={'gray video-page-top-decline'}
              label={'Remove from hold'}
              loading={holdLoading}
              onClick={hold}
              type={'button'}
            />
          )}
          {values.status !== 3 && values.status !== 0 && values.status !== 2 && !acceptLoading && !declineLoading && (
            <Button
              className={'gray video-page-top-hold'}
              label={'On hold'}
              loading={holdLoading}
              onClick={hold}
              type={'button'}
            />
          )}
          {values.status !== 3 && values.status !== 0 && !acceptLoading && !holdLoading && (
            <Button
              className={'gray video-page-top-hold'}
              label={'Decline'}
              loading={declineLoading}
              onClick={decline}
              type={'button'}
            />
          )}
          {values.status !== 3 && values.status !== 2 && !declineLoading && !holdLoading && (
            <Button
              className={'blue video-page-top-accept'}
              label={'Accept'}
              loading={acceptLoading}
              onClick={accept}
              type={'button'}
              disabled={isAcceptDisabled()}
            />
          )}
        </div>
      </div>
      <div className="video-page-inner">
        <div className="row">
          <div className="video-page-files">
            <span className="video-page-block-title">Files</span>
            {player}
            <div className="video-page-files-elements">
              <VideoFiles
                videoId={id}
                onChangeFiles={(files: any) => setFiles(files)}
                initialFiles={files}
                activeFileId={activeFileId}
                onChangeActiveFileId={setActiveFileId}
                clickable={true}
                moderation={true}
                onMovePress={onMovePress}
              />
            </div>
          </div>
          <div className="video-page-general-info">
            <span className="video-page-block-title">General Information</span>
            <Field
              className={"create-page-block-element"}
              id="videoTitle"
              label="Title *"
              type="textarea"
              options={{
                small: true
              }}
              error={errors.title}
              value={values.title}
              onChange={(value) => changeField("title", value)}
            />
            <Field
              className={"create-page-block-element"}
              id="videoTitle"
              label="Title for partners"
              type="textarea"
              options={{
                small: true
              }}
              value={values.title_partners}
              onChange={(value) => changeField("title_partners", value)}
            />
            <div className="video-page-general-info-row">
              <div className="video-page-general-info-col">
                <Field
                  className={"create-page-block-element"}
                  id="videoLocation"
                  label="Location *"
                  type="location"
                  value={values.location}
                  error={errors.location}
                  onChange={(value) => changeField("location", value)}
                />
              </div>
              <div className="video-page-general-info-col">
                <Field
                  className={"create-page-block-element"}
                  id="videoFilmingDate"
                  label="Filming date"
                  type="datetime"
                  value={values.filmingDate}
                  options={{
                    maxDate: moment().toDate()
                  }}
                  onChange={(value) => changeField("filmingDate", value)}
                />
              </div>
            </div>
            <Field
              className={"create-page-block-element"}
              id="videoCategories"
              label="Categories *"
              type="multiSelect"
              options={{
                onLoadElements: apiCategoriesGet,
                placeholder: 'Please select category',
              }}
              value={values.categories}
              onChange={(value) => changeField("categories", value)}
            />
            <Field
              className={"create-page-block-element"}
              id="videoTags"
              label="Tags"
              type="multiSearch"
              value={values.tags}
              onChange={(value) => changeField("tags", value)}
              options={{
                placeholder: 'Start type tag',
                onSearch: apiVideoTagsSearch
              }}
            />
            <div className="video-page-general-info-row">
              <div className="video-page-general-info-col">
                <Field
                  className={"create-page-block-element"}
                  id="videoEmail"
                  label="Author email"
                  type="input"
                  options={{
                    tips: ['IDF', 'DVIDS USA', 'Quds News Network', 'PRCS', 'Palestine Action', '@Mehrnews', '@PSCupdates'].map(tip => {
                      return {
                        label: tip,
                        value: tip
                      }
                    })
                  }}
                  value={values.authorEmail}
                  onChange={(value) => changeField("authorEmail", value)}
                />
              </div>
              <div className="video-page-general-info-col">
                <Field
                  className={"create-page-block-element"}
                  id="videoPublic"
                  label="Public"
                  type="singleSelect"
                  value={values.inGallery}
                  options={{
                    elements: ['Yes', 'No']
                  }}
                  onChange={(value) => changeField("inGallery", value)}
                />
              </div>
            </div>
            <div className="create-page-block-element-inner">
              <button onClick={copyDescription} className="create-page-block-element-copy-button">Copy</button>
              <Field
                className={"create-page-block-element"}
                id="videoDescription"
                label="Description *"
                type="textarea"
                error={errors.description}
                value={values.description}
                options={{
                  minRows: 4
                }}
                onChange={(value) => changeField("description", value)}
              />
            </div>
            <Field
              className={"create-page-block-element"}
              id="videoDescription"
              label="Description for partners"
              type="textarea"
              value={values.description_partners}
              onChange={(value) => changeField("description_partners", value)}
            />
            <div className="video-page-general-info-bottom">
              <Button
                className={'gray bottom-area-cancel-button'}
                label={'Copy video'}
                loading={copyLoading}
                onClick={copy}
                type={'button'}
              />
              <div className="video-page-general-info-bottom-right">
                <Button
                  className={'blue bottom-area-submit-button'}
                  label={'Save'}
                  loading={saveLoading}
                  onClick={save}
                  type={'button'}
                />
              </div>
            </div>
          </div>
          <div className="video-page-right">
            {id !== undefined && <Partners videoId={id} disabled={isAcceptDisabled()} onSave={() => {
              save()
            }}/>}
            {values.status === 2 && values.inGallery === 'Yes' && (
              <div className="video-page-upload-block">
                <span className="video-page-block-title">Gallery link?</span>
                <div className="video-page-upload-elements">
                  <a target={"_blank"} href={`https://stringershub.com/gallery/${video['hash']}`} className="video-page-upload-element-link">https://stringershub.com/gallery/{video['hash']}</a>
                </div>
              </div>
            )}
            {video && video.approved_at !== null && (
              <div className="video-page-upload-block">
                <span className="video-page-block-title">Approved At?</span>
                <div className="video-page-upload-elements">
                  {moment(`${video.approved_at}.000000Z`).format('DD/MM/YYYY HH:mm:ss')}
                </div>
              </div>
            )}
            {user !== null && (
              <div className="video-page-upload-block">
                <span className="video-page-block-title">Who uploaded?</span>
                {user.role === 'partner' && (
                  <div className="video-page-upload-elements">
                    <span className="video-page-upload-element">Partner: <b>{user.first_name}</b></span>
                  </div>
                )}
                {user.role === 'stringer' && (
                  <div className="video-page-upload-elements">
                    <span className="video-page-upload-element">Stringer: <NavLink target={"_blank"} className="video-page-upload-element-link" to={`/panel/stringers/${user.stringer_id}`}><><b>{user.first_name} {user.last_name}</b></></NavLink></span>
                    <span className="video-page-upload-element">Email: <b>{user.email}</b></span>
                    {user.comment && (
                      <div className="video-page-upload-element-block">
                        <span className="video-page-upload-element-block-label">Comment:</span>
                        <span className="video-page-upload-element-block-text">{user.comment}</span>
                      </div>
                    )}
                  </div>
                )}
                {user.role === 'admin' && (
                  <div className="video-page-upload-elements">
                    <span className="video-page-upload-element">Moderator:</span>
                    <span className="video-page-upload-element">Email: <b>{user.email}</b></span>
                  </div>
                )}
                {values.foreignId && <span className="video-page-upload-element">Partner ID: <b>{values.foreignId}</b></span>}
                <Button
                  className={'video-page-change-owner-button gray'}
                  label={'Change owner'}
                  loading={false}
                  onClick={() => {
                    openChangeAuthorModal()
                  }}
                  type={'button'}
                />
              </div>
            )}
            {id !== undefined && <Sellings videoId={id}/>}
          </div>
        </div>
      </div>
    </>
  );
}

export default VideoPage;
