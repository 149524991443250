import React, {useState} from 'react';
import {CLOUDFRONT_URL} from "../../core/common";
import Tabs from "../ui/Tabs";
import StringerGeneralInfo from "./components/StringerGeneralInfo";
import StringerModalGeneralInfo from "./components/modals/StringerModalGeneralInfo";
import StringerModalEquipment from "./components/modals/StringerModalEquipment";
import StringerModalSkills from "./components/modals/StringerModalSkills";
import StringerVideos from "./components/StringerVideo";
import StringerModalPro from "./components/modals/StringerModalPro";
import StringerPortfolio from "./components/StringerPortfolio";
import useModal from "../../hooks/useModal";
import DeleteProfileModal from "../modals/DeleteProfileModal";
import SignOutModal from "../modals/SignOutModal";
import queryString from "query-string";
import {useSelector} from "react-redux";

type StringerType = {
  stringer: any,
  editable: boolean,
  onUpdate?: () => void
}

const checkInitialTab = () => {

  const query: any = queryString.parse(window.location.search);
  if (query['tab'] === 'videos') {
    return 'Uploaded videos'
  }

  return 'General Info'

}

function Stringer({stringer, editable, onUpdate} :  StringerType) {

  const ownerUser = useSelector(state => state.user.user)

  const [currentTab, setCurrentTab] = useState(checkInitialTab());

  const [generalInfoModalOpen, openGeneralInfoModal, closeGeneralInfoModal] = useModal();
  const [equipmentsModalOpen, openEquipmentsModal, closeEquipmentsModal] = useModal();
  const [skillsModalOpen, openSkillsModal, closeSkillsModal] = useModal();
  const [proModalModalOpen, openProModal, closeProModal] = useModal();
  const [deleteModalOpen, openDeleteModal, closeDeleteModal] = useModal();
  const [signOutModalOpen, openSignOutModal, closeSignOutModal] = useModal();

  const user = stringer.user

  let tabs = ['General Info', 'Uploaded videos', 'Portfolio']
  if (ownerUser.company_id !== 1) {
    tabs = ['General Info']
  }

  return (
    <div className="stringer-page" style={{marginTop: 20}}>
      <DeleteProfileModal
        open={deleteModalOpen}
        onClose={closeDeleteModal}
      />
      <SignOutModal
        open={signOutModalOpen}
        onClose={closeSignOutModal}
      />
      <StringerModalPro
        open={proModalModalOpen}
        onClose={closeProModal}
        stringer={stringer}
        onUpdate={() => {
          onUpdate && onUpdate()
        }}
      />
      <StringerModalGeneralInfo
        open={generalInfoModalOpen}
        onClose={closeGeneralInfoModal}
        onUpdate={() => {
          closeGeneralInfoModal()
          onUpdate && onUpdate()
        }}
        stringer={stringer}
      />
      <StringerModalEquipment
        open={equipmentsModalOpen}
        onClose={closeEquipmentsModal}
        onUpdate={() => {
          closeEquipmentsModal()
          onUpdate && onUpdate()
        }}
        stringer={stringer}
      />
      <StringerModalSkills
        open={skillsModalOpen}
        onClose={closeSkillsModal}
        onUpdate={() => {
          closeSkillsModal()
          onUpdate && onUpdate()
        }}
        stringer={stringer}
      />
      <div className="stringer-page-inner">
        <div className="stringer-page-top">
          {user.image === null &&
            <div className="stringer-page-top-avatar" style={{backgroundImage: 'url(/images/stringer-avatar.svg)'}}/>
          }
          {user.image !== null &&
            <div className="stringer-page-top-avatar" style={{backgroundImage: `url(${CLOUDFRONT_URL}avatars/${user.image})`}}/>
          }
          <div className="stringer-page-top-info">
            <span className="stringer-page-top-info-name">{user.first_name} {user.last_name}</span>
            <span className="stringer-page-top-info-location"><i className="fa fa-map-marker"/> {stringer.location_text}</span>
            <div className="stringer-page-top-info-stars">
              <img src="/images/star.svg" alt="" className="stringer-page-top-info-star"/>
              <img src="/images/star.svg" alt="" className="stringer-page-top-info-star"/>
              <img src="/images/star.svg" alt="" className="stringer-page-top-info-star"/>
              <img src="/images/star.svg" alt="" className="stringer-page-top-info-star"/>
              <img src="/images/star.svg" alt="" className="stringer-page-top-info-star"/>
            </div>
          </div>
          {stringer.pro === 1 && (
            <div className="stringer-page-top-pro-link">
              <img src="/images/pro.svg" alt="" className="stringer-page-top-pro-link-icon"/>
              <span className="stringer-page-top-pro-link-label">PRO</span>
            </div>
          )}
        </div>
        <div className="stringer-page-content">
          <div className="stringer-page-aside">
            <div className="stringer-page-aside-block-1">
              <div className="stringer-page-balance">
                <span className="stringer-page-balance-value">${stringer.balance}</span>
                <span className="stringer-page-balance-label">Current balance</span>
              </div>
              <div className="stringer-page-info-element">
                <span className="stringer-page-info-element-value">${stringer.total_earning}</span>
                <span className="stringer-page-info-element-label">Total Earnings</span>
              </div>
              <div className="stringer-page-info-row">
                <div className="stringer-page-info-element">
                  <span className="stringer-page-info-element-value">{stringer.videos_count}</span>
                  <span className="stringer-page-info-element-label">Uploaded Videos</span>
                </div>
                <div className="stringer-page-info-element">
                  <span className="stringer-page-info-element-value">{stringer.videos_count_solded}</span>
                  <span className="stringer-page-info-element-label">Solded Videos</span>
                </div>
              </div>
              <div className="stringer-page-info-row">
                <div className="stringer-page-info-element" style={{marginLeft: '25%'}}>
                  <span className="stringer-page-info-element-value">0</span>
                  <span className="stringer-page-info-element-label">Completed tasks</span>
                </div>
              </div>
              {editable && stringer.pro === 0 && (
                <div className="stringer-page-pro-block">
                  <div className="stringer-page-pro-block-icon"/>
                  <span className="stringer-page-pro-block-text">To get various perks on the platform, you can apply for a PRO. Our experts will review your request   and approve if you will be qualified enough.</span>
                  {stringer.pro_moderation === 0 && (
                    <button className="stringer-page-pro-block-button" onClick={openProModal}>Apply for a PRO</button>
                  )}
                  {stringer.pro_moderation === 1 && (
                    <span className="stringer-page-pro-block-status">On moderation</span>
                  )}
                  <span className="stringer-page-pro-block-status-text">We will review your request within 1-2 working days</span>
                </div>
              )}
            </div>
            <div className="stringer-page-aside-block-3">
              {editable && <span onClick={openSignOutModal} className="stringer-page-aside-sign-out">Sign Out</span>}
              {editable && <span onClick={openDeleteModal} className="stringer-page-aside-delete">Delete account</span>}
            </div>
          </div>
          <div className="stringer-page-right">
            <Tabs
              tabs={tabs}
              currentTab={currentTab}
              className={'stringer-page-tabs'}
              onChange={(tab) => setCurrentTab(tab)}
            />
            <div className="stringer-page-right-content">
              {currentTab === 'General Info' &&
                <StringerGeneralInfo
                  editable={editable}
                  stringer={stringer}
                  onOpenGeneralInfoModal={openGeneralInfoModal}
                  onOpenEquipmentsModal={openEquipmentsModal}
                  onOpenSkillsModal={openSkillsModal}
                />
              }
              {currentTab === 'Uploaded videos' &&
                <StringerVideos
                  editable={editable}
                  stringer={stringer}
                />
              }
              {currentTab === 'Portfolio' &&
                <StringerPortfolio
                  editable={editable}
                  stringer={stringer}
                />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Stringer;
