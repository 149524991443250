import React from 'react';
import ConfirmModal, {BaseModalProps} from "./ConfirmModal";
import {apiStringerDeleteRequest} from "../../actions/stringerActions";
import {useDispatch, useSelector} from "react-redux";
import {userSignOutAction} from "../../reducers/user";
import {useNavigate} from "react-router-dom";
import useModal from "../../hooks/useModal";

function DeleteProfileModal({onClose, open}: BaseModalProps) {

  const [successModalOpen, openSuccessModal, closeSuccessModal] = useModal();

  const dispatch = useDispatch()
  const navigate = useNavigate()
  const token = useSelector(state => state.user.token)

  return (
    <>
      <ConfirmModal
        open={successModalOpen}
        onClose={closeSuccessModal}
        title='Success'
        message='Your account has been successfully deleted'
        submitButtonText='OK'
        onUpdate={() => {
          dispatch(userSignOutAction());
          navigate('/');
        }}

      />
      <ConfirmModal
        open={open}
        onClose={onClose}
        onUpdate={() => {
          onClose()
          openSuccessModal()
        }}
        title='Delete account?'
        message='Are you sure you want to delete your profile?'
        submitButtonText='Delete'
        cancelButtonText='Cancel'
        callback={async () => {
          await apiStringerDeleteRequest(token)
        }}
      />
    </>
  )

}

export default DeleteProfileModal
