import axios from "axios";
import {API_URL} from "../core/common";
import Cookies from 'js-cookie';

export const userSession = () => {

  return new Promise<null | {user: object, token: string}>(async (resolve, reject) => {

    const token = Cookies.get('token');

    if (token !== undefined) {

      try {

        const apiResponse = await apiUserSession(token);
        resolve({
          token: apiResponse.token,
          user: apiResponse.user
        });

      } catch (error) {
        resolve(null);
      }

    }

    resolve(null);

  })

}

export const apiUserLogin = (email: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.login', {
      email,
      password
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserRegister = (email: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.register', {
      email,
      password
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserForgotPassword = (email: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.send_recovery_password', {
      email
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserSession = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + '/user.session', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserGetContacts = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + '/contacts.get', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data.contacts);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserActivate = (token: string, id: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.activate', {
      token,
      id
    }, {
      headers: {
        Accept: 'application/json',
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserChangePassword = (token: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.change_password', {
      password,
    }, {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserResetPassword = (token: string, password: string): any => {

  return new Promise((resolve, reject) => {

    axios.post(API_URL + '/user.reset_password', {
      password,
      token
    }, {
      headers: {
        Accept: 'application/json',
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export const apiUserGetProfile = (token: string): any => {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + '/user.get_profile', {
      headers: {
        Accept: 'application/json',
        Authorization: 'Bearer ' + token,
      },
    }).then(function (response) {
      resolve(response.data);
    }).catch(function (error) {
      reject(error);
    })

  })

}

export function apiUserSearch(
  token: string, cancelToken: any, email: string
) {

  return new Promise((resolve, reject) => {

    axios.get(API_URL + `/users.search?email=${email}` , {
      cancelToken,
      headers: {
        "Accept": "application/json",
        Authorization: 'Bearer ' + token,
      }
    }).then(function (response) {
      resolve(response.data.emails);
    }).catch(function (error) {
      if (!axios.isCancel(error)) {
        reject(error);
      }
    })

  })

}

